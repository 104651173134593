import { Box, Button, Flex, Icon } from '@hometime-ui/core'
import { Link } from 'gatsby'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import React from 'react'

const createPaginationObjects = (length, page, increment = 2) =>
  Array.from({ length }, (_, i) => ({
    link: `/blog/${i + increment}/`,
    index: i + increment,
    current: page === i + increment,
  }))

const Pagination = ({
  isFirst,
  isLast,
  prevPage,
  nextPage,
  numberOfPages,
  pageUrl,
  currentPage,
}) => {
  let navItems = [
    {
      link: pageUrl,
      index: 1,
      current: currentPage,
    },
  ]

  if (numberOfPages <= 3) {
    navItems = [
      ...navItems,
      ...Array.from({ length: numberOfPages - 1 }, (_, i) => ({
        link: `${pageUrl}${i + 2}/`,
        index: i + 2,
        current: currentPage === i + 2,
      })),
    ]
  } else {
    if (currentPage <= 3) {
      navItems = [
        ...navItems,
        ...createPaginationObjects(3, currentPage),
        {
          separator: true,
        },
        {
          link: `${pageUrl}${numberOfPages}/`,
          index: numberOfPages,
          current: false,
        },
      ]
    } else if (currentPage > numberOfPages - 3) {
      navItems = [
        ...navItems,
        {
          separator: true,
        },
        ...createPaginationObjects(4, currentPage, numberOfPages - 3),
      ]
    } else {
      navItems = [
        ...navItems,
        {
          separator: true,
        },
        ...createPaginationObjects(3, currentPage, currentPage - 1),
        {
          separator: true,
        },
        {
          link: `${pageUrl}${numberOfPages}/`,
          index: numberOfPages,
          current: false,
        },
      ]
    }
  }

  return (
    <Flex
      as="ul"
      justifyContent="center"
      alignItems="center"
      p={{ base: 4, md: 2 }}
      mt={{ base: 9, md: 18 }}
    >
      {!isFirst && (
        <Link to={prevPage}>
          <Button
            bg="none"
            minW="8"
            h="8"
            p="0"
            mr="1"
            _hover={{
              bg: 'gray.300',
              color: 'gray.800',
            }}
          >
            <Icon as={MdChevronLeft} color="gray.600" w="6" h="6" />
          </Button>
        </Link>
      )}
      <Box as="ul" d="flex" listStyleType="none">
        {navItems.map((item) => (
          <Box as="li" key={item.index}>
            {item.separator ? (
              <Flex
                alignItems="center"
                justifyContent="center"
                minW="8"
                h="8"
                p="0"
                color="gray.600"
                mr="1"
              >
                ...
              </Flex>
            ) : (
              <Link to={item.link}>
                <Button
                  minW="8"
                  h="8"
                  p="0"
                  mr="1"
                  color={currentPage === item.index ? 'gray.800' : 'gray.600'}
                  bg={currentPage === item.index ? 'gray.300' : 'none'}
                  _hover={{
                    bg: 'gray.300',
                    color: 'gray.800',
                  }}
                >
                  {item.index}
                </Button>
              </Link>
            )}
          </Box>
        ))}
      </Box>
      {!isLast && (
        <Link to={nextPage}>
          <Button
            bg="none"
            minW="8"
            h="8"
            p="0"
            ml="1"
            _hover={{
              bg: 'gray.300',
              color: 'gray.800',
            }}
          >
            <Icon as={MdChevronRight} color="gray.600" w="6" h="6" />
          </Button>
        </Link>
      )}
    </Flex>
  )
}

export default Pagination
