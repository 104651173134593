import { Badge, Box, Grid, Heading, Text } from '@hometime-ui/core'
import { extractPageDataForSEO } from '~/helpers/dataHelpers'
import { graphql } from 'gatsby'
import Image from '~/components/Image'
import PageWrapper from '~/components/PageWrapper'
import Pagination from '~/components/Pagination'
import React from 'react'
import SEO from '~/components/SEO'
import useScreen from '~/hooks/useScreen'
import WidthWrapper from '~/components/WidthWrapper'

const BlogList = ({ location, data, pageContext }) => {
  const screen = useScreen()
  const { currentPage, numberOfPages } = pageContext
  const { name } = data.contentfulPage

  const blogs = data.allContentfulArticle.edges.map((edge) => edge.node)
  const baseUrl = '/blog/'
  const isFirst = currentPage === 1
  const isLast = currentPage === numberOfPages
  const prevPage =
    currentPage - 1 === 1
      ? `${baseUrl}`
      : `${baseUrl}${(currentPage - 1).toString()}`
  const nextPage = `${baseUrl}${(currentPage + 1).toString()}`

  return (
    <PageWrapper path={location.pathname}>
      <SEO
        pathname={location.pathname}
        {...extractPageDataForSEO(data.contentfulPage)}
      />
      <Box py={{ base: 8, lg: 12 }} bg="white">
        <WidthWrapper>
          <Box textAlign="center">
            <Heading level={screen.isMobile ? 2 : 1} mb="4">
              {name}
            </Heading>
            <Text color="gray.700">
              A collection of insights and best practises for professional hosts
            </Text>
          </Box>
          <Grid
            templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
            gap="8"
            mt="12"
          >
            {blogs.map((blog, index) => {
              const featuredBlog = index === 0
              return (
                <Grid
                  as="a"
                  href={`/blog/${blog.slug}`}
                  key={index}
                  gridColumn={featuredBlog && { md: '1/-1' }}
                  mb={featuredBlog && { md: 4 }}
                  templateColumns={featuredBlog && { lg: '1fr 440px' }}
                  templateRows={{ base: 'auto 1fr' }}
                >
                  <Box
                    pos="relative"
                    h={{ base: '280px', lg: featuredBlog ? '100%' : '280px' }}
                    minH={featuredBlog && { lg: '360px' }}
                    borderRadius={{
                      base: featuredBlog ? '8px 8px 0 0' : '8px',
                      lg: featuredBlog ? '8px 0 0 8px' : '8px',
                    }}
                    overflow="hidden"
                  >
                    <Image w="100%" src={blog.bannerImage.fluid} />
                  </Box>
                  <Box
                    bg={featuredBlog && { base: 'gray.200' }}
                    p={featuredBlog && { base: 4, md: 8 }}
                    borderRadius={{
                      base: featuredBlog ? '0 0 8px 8px' : 0,
                      lg: featuredBlog ? '0 8px 8px 0' : 0,
                    }}
                  >
                    <Heading
                      level={screen.isDesktop && featuredBlog ? 2 : 3}
                      mt={{ base: featuredBlog ? 0 : 4 }}
                    >
                      {blog.title}
                    </Heading>
                    <Text mt="4">{blog.metaDescription}</Text>
                    <Box mt="2">
                      {blog.tags.map((tag, y) => {
                        return (
                          <Badge
                            colorScheme="base"
                            size="md"
                            key={y}
                            mr="2"
                            mt="2"
                          >
                            {tag}
                          </Badge>
                        )
                      })}
                    </Box>
                  </Box>
                </Grid>
              )
            })}
          </Grid>
          <Pagination
            isFirst={isFirst}
            isLast={isLast}
            prevPage={prevPage}
            nextPage={nextPage}
            numberOfPages={numberOfPages}
            pageUrl={baseUrl}
            currentPage={currentPage}
          />
        </WidthWrapper>
      </Box>
    </PageWrapper>
  )
}

export default BlogList

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allContentfulArticle(
      filter: { category: { eq: "Blog" } }
      sort: { fields: publishDate, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          tags
          title
          metaDescription
          publishDate(formatString: "MMMM DD, YYYY")
          slug
          bannerImage {
            description
            fluid(maxWidth: 1000, maxHeight: 800, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    contentfulPage(name: { eq: "Hometime Platform Blog" }) {
      ...basicPageInfo
    }
  }
`
